import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { Box, Container } from '@material-ui/core';
import { useCookies } from 'react-cookie';
import AllFields from 'src/components/tbxn/AllFields';
import Waiting from 'src/components/Waiting';

function TxbnAllFields() {
  const { tableview } = useParams();
  const [aryFilms, setFilms] = useState([]); // No data yet
  const [isLoading, setIsLoading] = useState(true); // Start showing wheel
  const [cookies, setCookie] = useCookies(['filter', 'status']); // These are the cookies we use
  const studio = localStorage.getItem('studio');
  let initialStatus = cookies.status;
  if (initialStatus === 'undefined' || initialStatus === undefined) {
    initialStatus = '0';
  }
  const [statusFilter, setStatusFilter] = useState(initialStatus);

  let initialFilter = cookies.filter;
  if (initialFilter === 'undefined' || initialFilter === undefined) {
    initialFilter = '';
  }
  const [filterName, setFilterName] = useState(initialFilter);

  useEffect(() => {
    // Save the search parameters for when we hit this page again
    setCookie('filter', filterName, { path: '/' });
    setCookie('statusFilter', statusFilter, { path: '/' });

    // Base URL
    let url = 'https://staging.dashboard.theboxofficenetwork.com/api/tbxnfilms?function=search';

    // Filter by wildcard
    if (filterName !== '' && filterName !== 'undefined' && filterName !== undefined) {
      url += '&wildcard=' + filterName;
    }
    // Filter by status
    if (statusFilter !== '' && statusFilter !== 'undefined' && statusFilter !== undefined) {
      url += '&status=' + statusFilter;
    }
    // Filter by studio
    if (studio === '' || studio === null || studio === 'ALL') {
      console.log('All studios');
    } else {
      url += '&studio_id=' + studio;
    }

    // Get the data
    fetch(url, {
      method: 'GET',
      headers: new Headers({
        Accept: 'application/json'
      })
    })
      .then((res) => res.json())
      .then((response) => {
        setFilms(response);
        setIsLoading(false);
      })
      .catch((error) => console.log(error));
  }, [filterName, statusFilter]);

  if (isLoading) {
    return (
      <Waiting />
    );
  }

  return (
    <>
      <Helmet>
        <title>Films</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 3
        }}
      >
        <Container maxWidth={false}>
          <Box sx={{ pt: 3 }}>
            <AllFields films={aryFilms.rows} tableStyle={tableview} studio={studio} />
          </Box>
        </Container>
      </Box>
    </>
  );
}

export default TxbnAllFields;
