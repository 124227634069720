import { Helmet } from 'react-helmet';
import React, { useState, useEffect } from 'react';
import { useCookies } from 'react-cookie';
import {
  Box,
  Container,
  Grid,
} from '@material-ui/core';
import Pagination from '@material-ui/lab/Pagination';
import FilmListToolBar from 'src/components/films/FilmListToolbar';
import ProductCard from 'src/components/product//ProductCard';
import Waiting from 'src/components/Waiting';

const ProductList = () => {
  const [clips, setClips] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [cookies, setCookie] = useCookies(['filter']);
  const [filterName, setFilterName] = useState(cookies.filter);
  const [statusFilter, setStatusFilter] = useState(cookies.statusFilter);

  useEffect(() => {
    setCookie('filter', filterName, { path: '/' });
    let url = 'https://staging.dashboard.theboxofficenetwork.com/api/clips?function=summary';
    // Filter by wildcard
    if (filterName !== '' && filterName !== 'undefined' && filterName !== undefined) {
      url += '&wildcard=' + filterName;
    }

    // Filter by status
    if (statusFilter !== '' && statusFilter !== 'undefined' && statusFilter !== undefined) {
      url += '&status=' + statusFilter;
    }

    fetch(url, {
      method: 'GET',
      headers: new Headers({
        Accept: 'application/json'
      })
    })
      .then((res) => res.json())
      .then((response) => {
        setClips(response.rows);
        setIsLoading(false);
      })
      .catch((error) => console.log(error));
  }, [filterName, statusFilter]);

  /*
  ** clipGrid (movie to object)
  */
  function clipGrid(aClips) {
    if (aClips == null) {
      return (<h2>no clips</h2>);
    }
    return (
      aClips.map((clip) => (
        <Grid
          item
          key={clip.clip_id}
          lg={4}
          md={6}
          xs={12}
        >
          <ProductCard product={clip} />
        </Grid>
      ))
    );
  }

  if (isLoading) {
    return (
      <Waiting />
    );
  }

  return (
    <>
      <Helmet>
        <title>Clips | The Boxoffice Network</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 3
        }}
      >
        <Container maxWidth={false}>
          <FilmListToolBar filterText={filterName} onFilter={setFilterName} statusFilter={statusFilter} onStatus={setStatusFilter} />
          <Box sx={{ pt: 3 }}>
            <Grid
              container
              spacing={3}
            >
              {clipGrid(clips)}
            </Grid>
          </Box>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              pt: 3
            }}
          >
            <Pagination
              color="primary"
              count={3}
              size="small"
            />
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default ProductList;
