import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { Link } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import LocalMoviesOutlined from '@material-ui/icons/LocalMoviesOutlined';
import CheckCircleOutlined from '@material-ui/icons/CheckCircleOutlined';
import ToBeDoneIcon from '@material-ui/icons/RadioButtonUnchecked';
import SubmittedIcon from '@material-ui/icons/TurnedIn';
import ApprovedIcon from '@material-ui/icons/ThumbUp';
import UploadedIcon from '@material-ui/icons/Publish';
import LanguageIcon from '@material-ui/icons/Language';
import {
  Avatar,
  Box,
  Card,
  Checkbox,
  IconButton,
  Table,
  TableBody,
  TableContainer,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Tooltip,
  Typography
} from '@material-ui/core';
import getInitials from 'src/utils/getInitials';
import FilmTitles from './FilmTitles';

const FilmListResults = ({ films, ...rest }) => {
  const [cookies, setCookie] = useCookies(['pageLimit']); // These are the cookies we use
  const [selectedCustomerIds, setSelectedCustomerIds] = useState([]);

  const sinitialLimit = cookies.pageLimit;
  let initialLimit = 10;
  if (sinitialLimit === 'undefined' || sinitialLimit === undefined) {
    initialLimit = 10;
  } else {
    initialLimit = parseInt(sinitialLimit, 10);
  }
  const [limit, setLimit] = useState(initialLimit);

  const [page, setPage] = useState(0);
  const [orderBy, setOrderBy] = useState('film_title');
  const [order, setOrder] = useState('asc');
  const [foreignMovie, setForeignMovie] = useState(0);
  const handleSelectAll = (event) => {
    let newSelectedCustomerIds;

    if (event.target.checked) {
      newSelectedCustomerIds = films.rows.map((film) => film.film_id);
    } else {
      newSelectedCustomerIds = [];
    }

    setSelectedCustomerIds(newSelectedCustomerIds);
  };
  useEffect(() => setPage(0), [films]);

  const flipDirection = (fieldName) => {
    /* If the field is the same as previous, just flip the selection */
    if (fieldName === orderBy) {
      if (order === 'asc') {
        setOrder('desc');
      } else {
        setOrder('asc');
      }
    } else {
      /* If the field has changed, force ascending but change order by */
      setOrderBy(fieldName);
      setOrder('asc');
    }
  };

  const handleSelectForeign = (event, id) => {
    setForeignMovie(id);
  };

  const handleSelectOne = (event, id) => {
    const selectedIndex = selectedCustomerIds.indexOf(id);
    let newSelectedCustomerIds = [];

    if (selectedIndex === -1) {
      newSelectedCustomerIds = newSelectedCustomerIds.concat(
        selectedCustomerIds,
        id
      );
    } else if (selectedIndex === 0) {
      newSelectedCustomerIds = newSelectedCustomerIds.concat(
        selectedCustomerIds.slice(1)
      );
    } else if (selectedIndex === selectedCustomerIds.length - 1) {
      newSelectedCustomerIds = newSelectedCustomerIds.concat(
        selectedCustomerIds.slice(0, -1)
      );
    } else if (selectedIndex > 0) {
      newSelectedCustomerIds = newSelectedCustomerIds.concat(
        selectedCustomerIds.slice(0, selectedIndex),
        selectedCustomerIds.slice(selectedIndex + 1)
      );
    }

    setSelectedCustomerIds(newSelectedCustomerIds);
  };

  const handleLimitChange = (event) => {
    setLimit(event.target.value);
    setCookie('pageLimit', event.target.value, { path: '/' });
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const genYoutubeLink = (url) => {
    if (url === '' || url == null) {
      return <span />;
    }

    return (
      <IconButton href={url} target="_blank" rel="noopener noreferrer">
        <LocalMoviesOutlined />
      </IconButton>
    );
  };

  const checkMark = (val, tipText, doneIcon) => {
    if (val === true) {
      return (
        <Tooltip color="primary" title={tipText} placement="top">
          {doneIcon}
        </Tooltip>
      );
    }
    return (
      <Tooltip color="secondary" title={tipText} placement="top">
        <ToBeDoneIcon />
      </Tooltip>

    );
  };

  const dateMark = (val, tipText) => {
    if (val !== null && val !== undefined) {
      return (
        <Tooltip color="primary" title={tipText} placement="top">
          <CheckCircleOutlined />
        </Tooltip>
      );
    }
    return (
      <Tooltip color="secondary" title={tipText} placement="top">
        <ToBeDoneIcon />
      </Tooltip>

    );
  };

  function descendingComparator(a, b, iorderBy) {
    console.log('a=' + a[iorderBy]);
    console.log('b=' + b[iorderBy]);
    let ax = a[iorderBy];
    let bx = b[iorderBy];
    if (ax == null) ax = '';
    if (bx == null) bx = '';
    if (bx < ax) {
      return -1;
    }
    if (bx > ax) {
      return 1;
    }
    return 0;
  }

  function getComparator(iorder, iorderBy) {
    return iorder === 'desc'
      ? (a, b) => descendingComparator(a, b, iorderBy)
      : (a, b) => -descendingComparator(a, b, iorderBy);
  }

  function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const iorder = comparator(a[0], b[0]);
      if (iorder !== 0) return iorder;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }

  if (films.rows == null || films.rows.count === 0) {
    return (
      <Typography color="textPrimary" variant="body1">
        No films matched
      </Typography>
    );
  }

  // TableContainer sx={{ height: 900 }}
  return (
    <Card {...rest}>
      <FilmTitles MovieId={foreignMovie} handleParentClose={() => setForeignMovie(0)} />
      <PerfectScrollbar>
        <Box sx={{ minWidth: 1050 }}>
          <TableContainer>
            <Table size="small" stickyHeader aria-label="sticky table">
              <colgroup>
                <col width="10%" />
                <col width="25%" />
                <col width="10%" />
                <col width="10%" />
                <col width="15%" />
                <col width="10%" />
                <col width="10%" />
                <col width="10%" />
              </colgroup>
              <TableHead>
                <TableRow>
                  <TableCell size="small">
                    <Checkbox
                      checked={selectedCustomerIds.length === films.length}
                      color="primary"
                      indeterminate={
                        selectedCustomerIds.length > 0 && selectedCustomerIds.length < films.length
                      }
                      onChange={handleSelectAll}
                    />
                  </TableCell>

                  <TableCell size="small">
                    <TableSortLabel
                      active={orderBy === 'film_title'}
                      direction={order}
                      onClick={() => flipDirection('film_title')}
                    >
                      Name
                    </TableSortLabel>
                  </TableCell>

                  <TableCell size="small">
                    <TableSortLabel
                      active={orderBy === 'editor'}
                      direction={order}
                      onClick={() => flipDirection('editor')}
                    >
                      Clip Selector
                    </TableSortLabel>
                  </TableCell>

                  <TableCell size="small">Rip</TableCell>

                  <TableCell size="small">
                    <TableSortLabel
                      active={orderBy === 'raw_delivery_target'}
                      direction={order}
                      onClick={() => flipDirection('raw_delivery_target')}
                    >
                      Rough Target
                    </TableSortLabel>
                  </TableCell>

                  <TableCell size="small">Rough Actual</TableCell>

                  <TableCell size="small">Progress</TableCell>

                  <TableCell size="small">
                    <TableSortLabel
                      active={orderBy === 'final_cut_actual'}
                      direction={order}
                      onClick={() => flipDirection('final_cut_actual')}
                    >
                      Complete
                    </TableSortLabel>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {stableSort(films.rows, getComparator(order, orderBy))
                  .slice(page * limit, page * limit + limit)
                  .map((customer) => (
                    <TableRow
                      hover
                      key={customer.film_id}
                      selected={
                        selectedCustomerIds.indexOf(customer.film_id) !== -1
                      }
                    >
                      <TableCell size="small">
                        <Checkbox
                          checked={
                            selectedCustomerIds.indexOf(customer.film_id) !== -1
                          }
                          onChange={(event) => handleSelectOne(event, customer.film_id)}
                          value="true"
                        />
                        <IconButton onClick={(event) => handleSelectForeign(event, customer.movie_id)} rel="noopener noreferrer">
                          <LanguageIcon />
                        </IconButton>
                      </TableCell>
                      <TableCell size="small">
                        <Box
                          sx={{
                            alignItems: 'center',
                            display: 'flex'
                          }}
                        >
                          <Avatar src={customer.poster} sx={{ mr: 2 }}>
                            {getInitials(customer.film_title)}
                          </Avatar>
                          <Typography color="textPrimary" variant="body2">
                            <Link to={'/app/film/' + customer.film_id}>
                              {customer.film_title}
                            </Link>
                          </Typography>
                        </Box>
                      </TableCell>
                      <TableCell size="small">{customer.editor}</TableCell>
                      <TableCell size="small">{customer.rip_quality}</TableCell>
                      <TableCell size="small">
                        {customer.raw_delivery_target}
                      </TableCell>
                      <TableCell size="small">
                        {genYoutubeLink(customer.rough_playlist)}
                        {customer.raw_delivery_actual}
                      </TableCell>
                      <TableCell size="small">
                        <span>
                          {dateMark(customer.raw_delivery_actual, 'Rough Cut Uploaded')}
                          {checkMark(customer.thumbnail_approved, 'Thumbnail Produced', <CheckCircleOutlined />)}
                          {checkMark(customer.studio_approved, 'Studio Approved', <ApprovedIcon />)}
                        </span>
                      </TableCell>
                      <TableCell size="small">
                        <span>
                          {customer.final_cut_actual}
                        </span>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </PerfectScrollbar>
      <TablePagination
        component="div"
        count={films.rows.length}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleLimitChange}
        page={page}
        rowsPerPage={limit}
        rowsPerPageOptions={[10, 25, 75, 300]}
      />
    </Card>
  );
};

FilmListResults.propTypes = {
  films: PropTypes.object.isRequired
};

export default FilmListResults;
