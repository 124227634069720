import { React, useState } from 'react';
import {
  Box,
  Button,
  Card,
  CardContent,
  TextField,
  InputAdornment,
  SvgIcon
} from '@material-ui/core';
import { Search as SearchIcon } from 'react-feather';
import UnscheduledIcon from '@material-ui/icons/RadioButtonUnchecked';
import ToBeDoneIcon from '@material-ui/icons/DataUsage';
import SubmittedIcon from '@material-ui/icons/History';
import ApprovedIcon from '@material-ui/icons/ThumbUp';
import UploadedIcon from '@material-ui/icons/Publish';

const CustomerListToolbar = ({
  filterText, onFilter, filterStatus, onStatus, ...props
}) => {
  const [values, setValues] = useState({
    textFilter: filterText,
    statusFilter: filterStatus
  });

  const handleClick = () => {
    fetch('https://staging.dashboard.theboxofficenetwork.com/api/films?function=insert', {
      method: 'GET',
      headers: new Headers({
        Accept: 'application/json'
      })
    })
      .then((res) => res.json())
      .then((response) => {
        const id = response.rows[0].film_id;
        console.log('Made id = ' + id);
        const newPage = '/app/film/' + id;
        document.location = newPage;
      })
      .catch((error) => console.log(error));
  };

  /*
  ** handleChange - handle a change to the text search field
  */
  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value
    });
    onFilter(event.target.value);
  };

  /*
  ** handleStatusFilter - handle a change to the filter by status buttons
  */
  const handleTargetFilter = (filterNumber) => {
    setValues({
      ...values,
      statusFilter: filterNumber,
      textFilter: ''
    });
    onStatus(filterNumber);
    onFilter('');
  };

  return (
    <Box {...props}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end'
        }}
      >
        <Button onClick={() => handleTargetFilter(1)} startIcon={<UnscheduledIcon />}>Unscheduled</Button>

        <Button onClick={() => handleTargetFilter(2)} startIcon={<ToBeDoneIcon />}>To Be Done</Button>

        <Button onClick={() => handleTargetFilter(3)} startIcon={<SubmittedIcon />}>Awaiting Approval</Button>

        <Button onClick={() => handleTargetFilter(4)} startIcon={<ApprovedIcon />}>Approved</Button>

        <Button onClick={() => handleTargetFilter(5)} startIcon={<UploadedIcon />}>Final Cut Complete</Button>

        <Button onClick={() => handleTargetFilter(0)} sx={{ mx: 1 }}>All Films</Button>

        <Button color="primary" variant="contained" onClick={handleClick} sx={{ ml: 10 }}>
          Add film
        </Button>

      </Box>
      <Box sx={{ mt: 3 }}>
        <Card>
          <CardContent>
            <Box sx={{ maxWidth: 500 }}>
              <TextField
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SvgIcon fontSize="small" color="action">
                        <SearchIcon />
                      </SvgIcon>
                    </InputAdornment>
                  )
                }}
                placeholder="Search film"
                value={filterText}
                variant="outlined"
                name="filter"
                onChange={handleChange}
              />
            </Box>
          </CardContent>
        </Card>
      </Box>
    </Box>
  );
};

export default CustomerListToolbar;
