import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { Box, Container } from '@material-ui/core';
import { useCookies } from 'react-cookie';
import FilmListResults from 'src/components/films/FilmListResults';
import FilmListToolbar from 'src/components/films/FilmListToolbar';
import Waiting from 'src/components/Waiting';

function FilmList() {
  const [aryFilms, setFilms] = useState([]); // No data yet
  const [isLoading, setIsLoading] = useState(true); // Start showing wheel
  const [cookies, setCookie] = useCookies(['filter', 'status']); // These are the cookies we use

  let initialStatus = cookies.status;
  if (initialStatus === 'undefined' || initialStatus === undefined) {
    initialStatus = '0';
  }
  const [statusFilter, setStatusFilter] = useState(initialStatus);

  let initialFilter = cookies.filter;
  if (initialFilter === 'undefined' || initialFilter === undefined) {
    initialFilter = '';
  }
  const [filterName, setFilterName] = useState(initialFilter);

  useEffect(() => {
    // Save the search parameters for when we hit this page again
    setCookie('filter', filterName, { path: '/' });
    setCookie('statusFilter', statusFilter, { path: '/' });

    // Base URL
    let url = 'https://staging.dashboard.theboxofficenetwork.com/api/films?function=search';

    // Filter by wildcard
    if (filterName !== '' && filterName !== 'undefined' && filterName !== undefined) {
      url += '&wildcard=' + filterName;
    }
    // Filter by status
    if (statusFilter !== '' && statusFilter !== 'undefined' && statusFilter !== undefined) {
      url += '&status=' + statusFilter;
    }

    // Get the data
    fetch(url, {
      method: 'GET',
      headers: new Headers({
        Accept: 'application/json'
      })
    })
      .then((res) => res.json())
      .then((response) => {
        setFilms(response);
        setIsLoading(false);
      })
      .catch((error) => console.log(error));
  }, [filterName, statusFilter]);

  if (isLoading) {
    return (
      <Waiting />
    );
  }

  return (
    <>
      <Helmet>
        <title>Films</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 3
        }}
      >
        <Container maxWidth={false}>
          <FilmListToolbar filterText={filterName} onFilter={setFilterName} filterStatus={statusFilter} onStatus={setStatusFilter} />
          <Box sx={{ pt: 3 }}>
            <FilmListResults films={aryFilms} />
          </Box>
        </Container>
      </Box>
    </>
  );
}

export default FilmList;
