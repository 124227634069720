import {
  Box,
  TextField
} from '@material-ui/core';
import { useState } from 'react';

const studios = [
  {
    value: 'UNIV',
    label: 'UNIV'
  },
  {
    value: 'SONY',
    label: 'SONY'
  },
  {
    value: 'ALL',
    label: 'ALL'
  }
];

const Studio = ({ onStudioChange }) => {
  let studioValue = localStorage.getItem('studio');
  if (studioValue === undefined || studioValue === '') {
    studioValue = 'UNIV';
    localStorage.setItem('studio', studioValue);
  }
  const [studioState, setStudio] = useState(studioValue);
  console.log('Here we are in studio');
  const handleChange = (event) => {
    localStorage.setItem('studio', event.target.value);
    setStudio(event.target.value);
    onStudioChange(event.target.value);
    document.location.pathname = 'app/allfields/All%20Fields';
  };

  return (
    <TextField
      label="Studio"
      name="rip_quality"
      style={{ marginRight: 5, marginLeft: 5, marginBottom: 10 }}
      onChange={handleChange}
      select
      SelectProps={{ native: true }}
      value={studioState || ''}
      variant="outlined"
    >
      {studios.map((option) => (
        <option key={option.value} value={option.value}>
          {option.label}
        </option>
      ))}
    </TextField>
  );
};
export default Studio;
