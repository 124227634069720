/* eslint-disable react/destructuring-assignment */
import { useState, forwardRef } from 'react';
import { useCookies } from 'react-cookie';
import MaterialTable from '@material-table/core';
import {
  Checkbox
} from '@material-ui/core';
import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
};

function serializeObject(object) {
  const parameters = [];
  // eslint-disable-next-line no-restricted-syntax
  for (const property in object) {
    // eslint-disable-next-line no-prototype-builtins
    if (object.hasOwnProperty(property)) {
      parameters.push(encodeURI(property + '=' + object[property]));
    }
  }

  return parameters.join('&');
}

/*
** setData -- update row in the database
*/
function setData(index, aryData) {
  console.log(aryData[index]);
  const formData = serializeObject(aryData[index]);
  console.log('Saving data ' + formData);
  const url = 'https://staging.dashboard.theboxofficenetwork.com/api/tbxnfilms?function=update&film_id=' + aryData[index].film_id;
  console.log('to URL:' + url);
  fetch(url, {
    method: 'POST',
    body: formData,
    headers: new Headers({
      'Content-Type': 'application/x-www-form-urlencoded'
    })
  })
    .then((res) => res.json())
    .then(() => {
      console.log('Updated');
    })
    .catch((error) => console.log(error));
}

const AllFields = ({ films, tableStyle, ...rest }) => {
  let selColumns = [];

  const [cookies, setCookie] = useCookies(['pageLimit']); // These are the cookies we use
  const studioValue = localStorage.getItem('studio');
  console.log('Allfields with studio:' + studioValue);
  /* Code to handle rows per page. Way too much */
  const sinitialLimit = cookies.pageLimit;
  let initialLimit = 10;
  if (sinitialLimit === 'undefined' || sinitialLimit === undefined) {
    initialLimit = 10;
  } else {
    initialLimit = parseInt(sinitialLimit, 10);
  }
  const [limit, setLimit] = useState(initialLimit);
  const handleLimitChange = (newValue) => {
    setLimit(newValue);
    setCookie('pageLimit', newValue, { path: '/' });
  };

  /* Depending on the table type, change the array of fields */
  if (tableStyle === 'All Fields') {
    selColumns = [
      {
        title: 'Avatar',
        field: 'avatar',
        render: (rowData) => (
          <img
            style={{ height: 50, borderRadius: '10%' }}
            src={rowData.poster}
            alt="avatar"
          />
        )
      },
      {
        title: 'film_title',
        field: 'film_title'
      },
      { title: 'film_id', field: 'film_id' },
      { title: 'movie_id', field: 'movie_id' },
      { title: 'batch_number', field: 'batch_number' },
      { title: 'clip_count', field: 'clip_count' },
      { title: 'clip_guidance', field: 'clip_guidance' },
      { title: 'clipper', field: 'clipper' },
      { title: 'custom_id', field: 'custom_id' },
      { title: 'distrib_check', field: 'distrib_check' },
      { title: 'editor', field: 'editor' },
      { title: 'finalcut_month_year', field: 'finalcut_month_year' },
      { title: 'finalcut_actual', field: 'finalcut_actual' },
      { title: 'finalcut_complete', field: 'finalcut_complete' },
      { title: 'finalcut_directory', field: 'finalcut_directory' },
      { title: 'finalcut_exported', field: 'finalcut_exported' },
      { title: 'finalcut_issues', field: 'finalcut_issues' },
      { title: 'finalcut_size', field: 'finalcut_size' },
      { title: 'finalcut_status', field: 'finalcut_status' },
      { title: 'languages_primary', field: 'languages_primary' },
      { title: 'languages_subtitles', field: 'languages_subtitles' },
      { title: 'languages_total', field: 'languages_total' },
      { title: 'languages_voice', field: 'languages_voice' },
      { title: 'notes_feedback', field: 'notes_feedback' },
      { title: 'priority', field: 'priority' },
      {
        title: 'project_in_google_drive',
        field: 'project_in_google_drive'
      },
      { title: 'rip_quality', field: 'rip_quality' },
      {
        title: 'roughcut_checked',
        field: 'roughcut_checked',
        editComponent: (props) => (
          <Checkbox
            checked={
            props.value
          }
            onChange={(e) => props.onChange(e.target.value)}
            value="true"
          />
        )
      },
      { title: 'roughcut_date', field: 'roughcut_date' },
      { title: 'roughcut_playlist', field: 'roughcut_playlist' },
      { title: 'roughcut_uploaded', field: 'roughcut_uploaded' },
      { title: 'sourcing_complete', field: 'sourcing_complete' },
      { title: 'studio_approved', field: 'studio_approved' },
      { title: 'studio_id', field: 'studio_id' },
      { title: 'thumbnail_complete', field: 'thumbnail_complete' },
      { title: 'titles_complete', field: 'titles_complete' },
      { title: 'translations_complete', field: 'translations_complete' },
      { title: 'upload_youtube', field: 'upload_youtube' },
      { title: 'calltoaction_amazon', field: 'calltoaction_amazon' },
      { title: 'calltoaction_itunes', field: 'calltoaction_itunes' },
      { title: 'upload_facebook', field: 'upload_facebook' },
      { title: 'wip_status', field: 'wip_status' },
      { title: 'clips_chosen', field: 'clips_chosen' },
      { title: 'CS Name', field: 'name' }
    ];
  } else {
    selColumns = [
      { title: 'STUDIO', field: 'studio_id' },
      { title: 'MONTHYEAR-TAB', field: 'finalcut_month_year' },
      {
        title: 'Avatar',
        field: 'avatar',
        render: (rowData) => (
          <img
            style={{ height: 50, borderRadius: '10%' }}
            src={rowData.poster}
            alt="avatar"
          />
        )
      },
      { title: 'MOVIE-TITLE', field: 'film_title' },
      { title: 'film_id', field: 'film_id' },
      { title: '#CLIPS', field: 'clip_count' },
      { title: 'EDITORS', field: 'editor' },
      { title: 'WIP', field: 'wip_status' },
      {
        title: 'EXPORTS DONE',
        field: 'finalcut_exported',
        render: (rowData) => (
          <Checkbox
            checked={
            rowData.finalcut_exported
          }
            value="true"
          />
        ),
        editComponent: (props) => (
          <Checkbox
            checked={
            props.value
          }
            onChange={(e) => props.onChange(e.target.value)}
            value="true"
          />
        )
      },
      { title: 'DISTRIB_CHECK', field: 'distrib_check' },
      { title: 'LANGUAGES AVAILABLE', field: 'languages_total' },
      { title: 'DELIVERY DATE', field: 'finalcut_actual' },
      {
        title: 'GOOGLE DRIVE',
        field: 'project_in_google_drive'
      },
      { title: 'ISSUES ENCOUNTERED', field: 'finalcut_issues' },
      { title: 'NOTES FEEDBACK', field: 'notes_feedback' }
    ];
  }
  console.log('rerender all fields');
  if (films !== null) {
    return (
      <span>
        <MaterialTable
          editable={{
            onBulkUpdate: (changes) => new Promise((resolve, reject) => {
              setTimeout(() => {
                /* setData([...data, newData]); */

                resolve();
              }, 1000);
            }),
            onRowAddCancelled: (rowData) => console.log('Row adding cancelled'),
            onRowUpdateCancelled: (rowData) => console.log('Row editing cancelled'),
            onRowAdd: (newData) => new Promise((resolve, reject) => {
              setTimeout(() => {
                /* setData([...data, newData]); */

                resolve();
              }, 1000);
            }),
            onRowUpdate: (newData, oldData) => new Promise((resolve, reject) => {
              setTimeout(() => {
                const dataUpdate = [...films];
                const index = oldData.tableData.id;
                dataUpdate[index] = newData;
                setData(index, dataUpdate);

                resolve();
              }, 1000);
            }),
            onRowDelete: (oldData) => new Promise((resolve, reject) => {
              setTimeout(() => {
                const dataDelete = [...films];
                const index = oldData.tableData.id;
                dataDelete.splice(index, 1);
                // setData([...dataDelete]);

                resolve();
              }, 1000);
            })
          }}
          icons={tableIcons}
          columns={selColumns}
          data={films}
          title={tableStyle}
          onRowsPerPageChange={handleLimitChange}
          options={{
            filtering: true,
            search: true,
            pageSize: limit,
            rowStyle: { fontFamily: 'Roboto' }
          }}
        />
      </span>
    );
  }
  return <span />;
};
export default AllFields;
