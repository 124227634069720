/* eslint-disable react/jsx-one-expression-per-line */
import React, { useState, useRef } from 'react';
import {
  Box, Container, TextField, Button, Input
} from '@material-ui/core';
import { Helmet } from 'react-helmet';

function FileUploadPage() {
  const [selectedFile, setSelectedFile] = useState('');
  const [isFilePicked, setIsFilePicked] = useState(false);
  const [selectedType, setSelectedType] = useState('--select--');
  const [selectedStudioId, setSelectedStudioId] = useState('ALL');
  const [isFileUploaded, setFileUploaded] = useState(false); // No File uploaded yet
  const ref = useRef();

  const isTypePicked = () => {
    console.log(selectedType);
    if (selectedType === '--select--') {
      return (false);
    }
    return (true);
  };

  const changeHandler = (event) => {
    if (event.target.files.length === 0) {
      setIsFilePicked(false);
    } else {
      setSelectedFile(event.target.files[0]);
      setIsFilePicked(true);
    }
  };

  const handleChange = (event) => {
    setSelectedType(event.target.value);
    if (event.target.value === 'rips') {
      setSelectedStudioId('ALL');
    }
  };

  const handleReset = () => {
    ref.current.value = '';
    setIsFilePicked(false);
    setFileUploaded(false);
    setSelectedFile('');
  };

  const handleStudioId = (event) => {
    setSelectedStudioId(event.target.value);
  };

  const handleLoad = () => {
    let url = '';
    console.log('Type=' + selectedType);
    if (selectedType === 'stats') {
      url = 'https://staging.dashboard.theboxofficenetwork.com/api/datastudioload?studio=' + selectedStudioId + '&filename=' + selectedType + '_' + selectedStudioId + '.csv';
    } else {
      url = 'https://staging.dashboard.theboxofficenetwork.com/api/ripsload?studio=' + selectedStudioId + '&filename=' + selectedType + '_' + selectedStudioId + '.csv';
    }

    fetch(url)
      .then((response) => response.json())
      .then((result) => {
        console.log('Success:', result);
        handleReset();
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  };

  const handleSubmission = () => {
    const formData = new FormData();

    formData.append('File', selectedFile);
    const url = 'https://staging.dashboard.theboxofficenetwork.com/api/fileupload?studio=' + selectedStudioId + '&filetype=' + selectedType;
    console.log('Url=' + url);
    fetch(url, {
      method: 'POST',
      body: formData
    })
      .then((response) => response.json())
      .then((result) => {
        console.log('Success:', result);
        setFileUploaded(true);
      })
      .catch((error) => {
        console.error('Error:', error);
        setFileUploaded(false);
      });
  };

  return (
    <>
      <Helmet>
        <title>Upload</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 3
        }}
      >
        <Container maxWidth={false}>

          <TextField
            label="File Type"
            name="filetype"
            style={{ marginRight: 5, marginLeft: 5, marginBottom: 10 }}
            select
            onChange={handleChange}
            SelectProps={{ native: true }}
            value={selectedType || ''}
            variant="outlined"
          >
            <option key="--select---" value="--select--">
              --select--
            </option>
            <option key="rips" value="rips">
              RIPS
            </option>
            <option key="stats" value="stats">
              STATS
            </option>
          </TextField>
          <TextField
            label="Studio ID"
            name="studio_id"
            style={{
              marginRight: 5, marginLeft: 5, marginBottom: 5,
            }}
            select
            onChange={handleStudioId}
            SelectProps={{ native: true }}
            value={selectedStudioId || ''}
            variant="outlined"
          >
            <option
              key="ALL"
              value="ALL"
            >
              ALL
            </option>
            <option
              key="SONY"
              value="SONY"
            >
              SONY
            </option>
            <option
              key="UNIV"
              value="UNIV"
            >
              UNIV
            </option>
          </TextField>
          <Button
            variant="contained"
            component="label"
            color="secondary"
            style={{ height: 56, marginLeft: 10 }}
            onClick={handleReset}
          >
            Reset
          </Button>
          <hr />
          {isTypePicked() ? (
            <Button
              variant="contained"
              component="label"
              color="primary"
            >
              <input type="file" name="file" onChange={changeHandler} ref={ref} />
            </Button>
          ) : (
            <p />
          )}
          {isFilePicked ? (
            <div>
              <p>Filename: {selectedFile.name}</p>
              <p>Filetype: {selectedFile.type}</p>
              <p>Size in bytes: {selectedFile.size}</p>
              <p>File Type: {selectedType}</p>
              <p>Studio: {selectedStudioId}</p>
              <p>
                lastModifiedDate:{' '}
                {selectedFile.lastModifiedDate.toLocaleDateString()}
              </p>
            </div>
          ) : (
            <p />
          )}
          <div>
            {isFilePicked ? (
              <Button color="primary" variant="contained" type="button" onClick={handleSubmission}>Upload</Button>
            ) : (
              <div />
            )}

            {isFileUploaded ? (
              <Button color="primary" variant="contained" type="button" onClick={handleLoad}>Load</Button>
            ) : (
              <div />
            )}
          </div>

        </Container>
      </Box>
    </>
  );
}
export default FileUploadPage;
